// site logo
// all (ansillary, main, sub)
// search wrapper (ansillary wrapper)
// theme main nav
// theme sub nav
// is-fixed
// has-logo
// injected elements
// @mediaqueries
#topNav {

  .has-main-nav & {
    
    border-bottom: 5px solid $secondary-color;
    
  }

  &.topNav {
    //agnostic things
    // site logo
    background-color: #fff;
    #sn-site-logo {
      width: 123px;
      height: 120px;
      
      padding: 5px;
      
      
      background-color: transparent;
      
      
      border:0;
      
      transition: top 0.2s linear;
    }
    // all
    .theme-search-wrapper,
    .theme-main-nav,
    .theme-sub-nav {
      
      max-width:100%;
      
    }
    // search wrapper
    .theme-search-wrapper {
      background-color: $primary-color;
      
      max-width:100%;
      
      
      border: 0;
      
    }
    .theme-search-bar .searchBarText {
      padding: 0 15px;
      flex: 0 0 auto;
      white-space:pre;
    }
    .theme-search-bar .theme-search-box {
      padding-right: 15px;
    }
    .theme-search-bar  {
      
      max-width: 1200px;
      
      left: 0px;
      height:30px;
      .nav-fixed & {
        line-height: 0.01px;
        height: 0.01px;
      }
    }
    // network nav bar
    #networkNavBar {
      
    }
    // theme main nav
    .theme-main-nav {
      display: flex !important;
      align-items: center; // this might need to be pecific to layout 1
      background-color: #fff;
      
      max-width: 1200px;
      
      
      .theme-nav-style-dropdown > .theme-nav-item {
        border-right: 0;
      }
      
      .theme-nav {
        line-height: 30px;
        max-height: 30px;
      }
      // nav spacing not focusing on this right now
      // #topNav .theme-nav-style-dropdown > .theme-nav-item {
      //   margin: 0 20px;
      // }
      // #topNav .theme-nav-style-dropdown > .theme-nav-item:first-of-type {
      //   margin-left: 0px;
      // }
      // #topNav .theme-nav-style-dropdown > .theme-nav-item:last-of-type {
      //   margin-right: 0px;
      // }
      // typography declarations
      //nav items
      .theme-nav > .theme-nav-item > .theme-nav-link {
        font-family: $fontOne;
        font-weight: bold;
        font-size: 14px;
        color: $primary-color;
        text-transform: uppercase;
        background-color: transparent;
      }
      .theme-nav > .theme-nav-item > .theme-nav-link:not(x):hover {
        background-color: transparent;
      }
      .theme-nav > .theme-nav-item.selected > .theme-nav-link {
        background-color: transparent;
        color: $primary-color;
      }
      .theme-nav > .theme-nav-item:hover > .theme-nav-link {
        color: $secondary-color;
      }
      // nav item hasChild indicators
      .theme-nav-style-dropdown > .theme-nav-item.hasChild > .theme-nav-link:before {
        color: $primary-color;
        
        display: none;
        
      }
      .theme-nav-style-dropdown > .theme-nav-item.dropdown-open > .theme-nav-link:before {
        color: $secondary-color;
      }
      // dropdowns
      // dropdown container
      .theme-nav-style-dropdown ~ .theme-nav-dropdown {
        font-size: 14px;
        
        border-top: 1px solid rgba(255,255,255,0.1);
        
        
        border-bottom: 1px solid rgba(255,255,255,0.1);
        
        background-color: rgba(255,255,255,.8);
      }
      // dropdown item
      .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item {
        font-family: $fontOne;
        font-weight: bold;
        color: $primary-color;
        a { color: $primary-color; }
        text-transform: uppercase;
        
      }
      .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item.disabled > .theme-nav-link:hover,
      .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item > .theme-nav-link:hover {
        color: $secondary-color;
      }
      .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item > .theme-nav-link:not(x):hover {
        background-color: white;
      }
    }
    // theme sub nav
    .theme-sub-nav {
      font-family: $fontOne;
      font-weight: bold;
      font-size: 14px;
      background-color: #eaeaea;
      
      border: 0;
      

      .home:not(.newsPage) & {

        display: none !important;

      }

    }
    .theme-sub-nav .theme-nav {
      line-height: 40px;
    }
    .theme-nav-style-dropdown > .theme-nav-title a {
      color: #777777;
    }
    .theme-sub-nav .theme-nav > .theme-nav-item > .theme-nav-link {
      background-color: #dedede;
      color: #888888;
      text-transform: uppercase;
      
    }
    .theme-sub-nav .theme-nav > .theme-nav-item > .theme-nav-link:not(x):hover {
      background-color: #555555;
      color: #cccccc;
    }
    // if top-nav has logo
    //
    // if top-nav is fixed
    &.nav-fixed.has-site-logo .theme-search-bar,
    &.nav-fixed.has-site-logo .theme-nav-style-dropdown {
      left: 0;
    }
    // injected elements
    .headerCallToAction {
      width: initial;
      display:inline-block;
      text-align: center;
      margin:0 0px;
      a {
        
        border: 0px solid #000000;
        background-color: $primary-color;
        
        padding: 5px 15px;
        border-radius: 0px;
        font-family: $fontOne;
        font-weight: bold;
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 0;
        white-space: nowrap;
        :hover {
          text-decoration: none;
        }
      }
    }
    .social-media-links {
      .pageElement {
        margin:0;
      }
      .sn-social-media-list .sn-social-media-icon {
        background-color: $secondary-color;
        height: 30px;
        width: 30px;
        margin: 0 5px;
        float: left;
        
        border-radius: 0;
        
        &:before {
          width: 30px;
          line-height: 30px;
        }
      }
    }
    .heroPhotoElement {
      img {
        max-height: 50px;
        max-width: 100px;
      }
    }
    // beholden to layout 1 & 2
    &.layout-1,
    &.layout-2 {
      #sn-site-logo {
        top: 31px;
        margin: 0px;
        
        left: calc((100% - 1200px)/2);
        
        .nav-fixed & {
          top:1px;
        }
      }
      .theme-nav-style-dropdown {
        max-width: $maxWidth;
        margin: 0 auto;
        left: 0;
      }
      &.has-site-logo .theme-nav-style-dropdown {
        
        margin-left: calc(((100% - 1200px)/2) + 123px);
        
        max-width: $maxWidth - 123;
      }
      @media screen and (max-width:1260px) {
        .page-manager-visible &.has-site-logo .theme-nav-style-dropdown {
          margin-left: 123px;
        }
        .page-manager-visible & #sn-site-logo {
          left:0px;
        }
      }
      @media screen and (max-width:1200px) {
        &.has-site-logo .theme-nav-style-dropdown {
          margin-left: 123px;
        }
        & #sn-site-logo {
          left:0px;
        }
      }
    }
    // beholden to layout 2
    &.layout-2 {
      background-color: #fff;
      #sn-site-logo {
        top: calc(15px + 1px); // if it has a border bottom...
        transition: top 300ms ease, height 300ms ease;
        .nav-fixed & {
          top:0px;
          height: 30px;
        }
      }
      .theme-search-bar {
        max-width: 1077px;
        margin-left: calc(((100% - 1200px)/2) + 123px);
      }
      .theme-main-nav {
        height: 30px;
        background-color: transparent;
      }
      .theme-main-nav .theme-nav {
        background-color: #fff;
        padding-right: 30px;
        &:before {
          content: '';
          background: #fff;
          position: absolute;
          right: 90%;
          width: 100%;
          height: 30px;
          //fix for 1px gap issue
          margin-right: -1px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { margin-right: 100%; }

        }
        &:after {
          content: '';
          position: absolute;
          height: 0px;
          width: 0px;
          left: calc(100% - 30px);
          border: 15px solid #fff;
          border-left-color: #fff;
          border-top-color: #fff;
        }
      }
      @media screen and (max-width:1260px) {
        .page-manager-visible & .theme-search-bar {
          margin-left: 123px;
          width: calc(100% - 123px);
        }
      }
    }
    &.layout-3 {
      .site-tagline-text{
        opacity: 0;
      }
      #sn-site-logo {
        // margin-left: -61.5px;
        margin-left: 0;
        left: 50%;
        transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        top: 30px;
        transition: height 300ms linear, width 300ms linear, top 300ms linear;
        .nav-fixed & {
          top:1px !important;
          height: 80px; // 80px default
          width: 80px; // 80px default;
          margin-left: 0;
          left: 50%;
          transform: translate(-50%);
        }
        
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        justify-content: center;
        -webkit-justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        z-index: 1;
        padding: 0;
        border:0;
        background-color: transparent;
        .nav-fixed & {
          top:1px !important;
          height: 80px;
          width: 100%;
          &:before,
          &:after {
            width:calc((100% - 80px)/2);
          }
          .sn-site-logo-wrap {
            width: 80px;
            flex: 0 1 80px;
          }
        }
        &:before,
        &:after,
        .sn-site-logo-wrap {
          align-self: auto;
          min-width: 0;
          min-height: auto;
        }
        &:before {
          content: 'Mid States Club';
          order:1;
          flex:0 1 auto;
          -webkit-flex: 0 1 auto;
          justify-content: flex-end;
          -webkit-justify-content: flex-end;
          display: flex;
          display: -webkit-flex;
          justify-content: flex-end;
          display: flex;
        }
        .sn-site-logo-wrap {
          order:2;
          flex:0 1 123px;
          width: 123px;
          padding: 5px;
          margin: 0 10px;
          transition: width 300ms linear, flex 300ms linear;
          
          background-color: transparent;
          
          
        }
        &:after {
          content: 'Hockey Association';
          order:3;
          flex:0 1 auto;
        }
        &:before,
        &:after {
          width:calc((100% - 123px)/2);
          font-family: $fontOne;
          font-weight: bold;
          font-size: 44px;
          color: $secondary-color;
          text-transform: uppercase;
          transition: width 300ms linear;

          @media only screen and (max-width: 1024px){ display: none; }

        }
        
      }
      .theme-main-nav .theme-nav {
        display: flex;
        justify-content: center;
        line-height: 30px;
        padding-top: 120px;
        max-width: 1200px;
        left: 0;
        transition: padding-top 300ms linear;
        max-height: initial;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { max-height: none; }

        .nav-fixed & {
          padding-top: 80px;
        }
      }
      .theme-main-nav .theme-nav > .theme-nav-item > .theme-nav-link {
        border-bottom: 5px solid transparent;
        margin-bottom: - 5px;
        transition: border-bottom-color 300ms linear;
      }
      .theme-main-nav .theme-nav > .theme-nav-item.selected > .theme-nav-link {
        border-bottom: 5px solid $primary-color;
      }
      .theme-main-nav .theme-nav > .theme-nav-item:hover > .theme-nav-link {
        border-bottom: 5px solid $primary-color;
      }
      .theme-sub-nav .theme-nav {
        display: flex;
        justify-content: center;
        max-width: 1200px;
        left: 0;
      }
      .theme-search-wrapper {
        display: flex;
        padding: 0 calc((100% - 1200px)/2);
        height: 30px;
        transition: height 300ms linear;
      }
      .nav-fixed & .theme-search-wrapper {
        height: 0.01px;
      }
      .headerCallToAction {
        position: absolute;
        top: calc(60px + 15px);
        right: calc((100% - 1200px)/2);
        transition: top 300ms linear;
        
        z-index: 1;
        
        .nav-fixed & {
          top: calc(60px - 15px);
        }
      }
      .social-media-links {
        display: flex;
        align-items: center;
      }
      @media screen and (max-width:1260px) {
        .page-manager-visible & .headerCallToAction {
          right:calc((100% - 100%)/2);
        }
      }
      @media screen and (max-width:1200px) {
        & .headerCallToAction {
          right:calc((100% - 100%)/2);
        }
      }
      + #topNavPlaceholder {
        height:calc((
        
        30 +
        
        
        35 +
        120
        
        )*1px);
      }
      .has-sub-nav & + #topNavPlaceholder {
        height:calc((
        
        30 +
        
        
        35 +
        120 +
        
        
        40
        
        )*1px);
      }
    }
    &.layout-4 {

      $navPadding: calc((100% - 1200px)/2);
.theme-main-nav {
  max-width: 100%;

  .theme-nav-style-dropdown {
    &:after {
      content: 'Mid States Club';
      font-family: $fontOne;
      font-weight: bold;
      font-size: 44px;
      color: $secondary-color;
      text-transform: uppercase;
      transition: width 300ms linear;
      transition: font-size 300ms linear;
      height: 120px;
      position: absolute;
      display: block;
      top: -120px;
      left: 0;
      line-height: 100px;
      padding: 0 $navPadding;
      margin: 0 15px;
      width: max-content;
      
      

      @media only screen and (max-width: 1024px){ display: none; }

    }
    .nav-fixed &:after {
      line-height: 80px;
      font-size: 24px;
      top: -80px;
    }
  }

}
#sn-site-logo {
  left: 0;
  top: 30px;
  transition: height 300ms linear, width 300ms linear, top 300ms linear;

  + .theme-search-wrapper + .theme-main-nav {
    .theme-nav-style-dropdown:after { display: none; }
  }

  .nav-fixed & {
    top: 0;
    height: 80px; // 80px default
    width: 80; // 80px default;

    
      &:after {
        line-height: 80px;
        font-size: 24px;
      }
    

  }

  
  z-index: 1;
  margin-left:$navPadding;
  border:0;
  background-color: transparent;
  width: auto;
  float: left;
  overflow: visible;
  @media only screen and (max-width: 1250px){ margin-left: 0; }

  .theme-nav-style-dropdown:after { display: none; }

  &:after,
  .sn-site-logo-wrap {
    align-self: auto;
    min-width: 0;
    min-height: auto;

    @media only screen and (min-width: 1024px){ display: block; }

  }
  .sn-site-logo-background {
    background-position: left center;
    background-size: 0 0;
    display: inline;

    img {
      height: 100%;

      @media only screen and (max-width: 1024px){ display: none; }

    }

    @media only screen and (max-width: 1024px){
      background-position: center center;
      background-size: auto 100%;
      display: block;
      height: 24px;
    }

  }
  @media only screen and (max-width: 1024px){ padding: 0; }
  .sn-site-logo-wrap {
    width: auto;
    padding: 10px 0;
    margin: 0;
    transition: width 300ms linear;
    
    background-color: transparent;
    
    
    @media only screen and (max-width: 1024px){ display: block; }
  }
  &:after {
    content: 'Mid States Club';
    font-family: $fontOne;
    font-weight: bold;
    font-size: 44px;
    color: $secondary-color;
    text-transform: uppercase;
    transition: width 300ms linear;
    transition: font-size 300ms linear;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 100%;
    line-height: 100px;
    padding: 0 15px;
    width: max-content;

    @media only screen and (max-width: 1024px){ display: none; }

  }
  
}
.theme-main-nav {

  .theme-nav {
    display: flex;
    line-height: 30px;
    margin-top: 120px;
    max-width: 100%;
    left: 0;
    transition: line-height 300ms linear;
    transition: margin-top 300ms linear;
    max-height: initial;
    padding: 0 $navPadding;

    .nav-fixed & {
      margin-top: 80px;
      line-height: 40px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { max-height: none; }

    &.theme-nav-style-dropdown { background: #fff; }

  }

  .theme-nav-style-dropdown ~ .theme-nav-dropdown.dropdown-open { top: 160px !important; }

  .nav-fixed & {
    .theme-nav-style-dropdown ~ .theme-nav-dropdown.dropdown-open { top: 120px !important; }
  }

}
.theme-main-nav .theme-nav > .theme-nav-item > .theme-nav-link {
  border-bottom: 5px solid transparent;
  margin-bottom: -1 - 5px;
  transition: border-bottom-color 300ms linear;
}
.theme-main-nav .theme-nav > .theme-nav-item.selected > .theme-nav-link {
  border-bottom: 5px solid $primary-color;
}
.theme-main-nav .theme-nav > .theme-nav-item:hover > .theme-nav-link {
  border-bottom: 5px solid $primary-color;
}
.theme-sub-nav .theme-nav {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  left: 0;
}
.theme-search-wrapper {
  display: flex;
  padding: 0 calc((100% - 1200px)/2);
  height: 30px;
  transition: height 300ms linear;
}
.nav-fixed & .theme-search-wrapper {
  height: 0.01px;
}
.headerCallToAction {
  position: absolute;
  top: calc(60px + 15px);
  right: calc((100% - 1200px)/2);
  transition: top 300ms linear;
  z-index: 1;
  .nav-fixed & {
    top: calc(40px - 15px);
  }
}
.social-media-links {
  display: flex;
  align-items: center;
}
@media screen and (max-width:1260px) {
  .page-manager-visible & .headerCallToAction {
    right:calc((100% - 100%)/2);
  }
}
@media screen and (max-width:1200px) {
  & .headerCallToAction {
    right:calc((100% - 100%)/2);
  }
}
+ #topNavPlaceholder {
  height:calc((
  
  30 +
  
  
  35 +
  120
  
  )*1px);
}
.has-sub-nav & + #topNavPlaceholder {
  height:calc((
  
  30 +
  
  
  35 +
  120 +
  
  
  40
  
  )*1px);
}

&.nav-fixed + #topNavPlaceholder {
  height:calc((
  
  
  45
  
  )*1px);
}

.has-sub-nav &.nav-fixed + #topNavPlaceholder {
  height:calc((
  
  
  45 +
  
  
  40
  
  )*1px);
}

    }

    &.nav-fixed + #topNavPlaceholder {
      height:calc((
      
      80 +
      
      
      35
      
      )*1px);
    }

    .has-sub-nav &.nav-fixed + #topNavPlaceholder {
      height:calc((
      
      80 +
      
      
      35 +
      
      
      40
      
      )*1px);
    }

    @media screen and (max-width:1024px) {
      .headerCallToAction,
      .theme-search-wrapper,
      .theme-main-nav {
        display: none !important;
      }

      #sn-site-logo {
        height: 44px;
        top: 0 !important;
        padding: 10px;
        margin: 0 50px !important;
        width: calc(100% - 100px) !important;
        left: 0 !important;
        transform: none !important;
      }

    }
  }

  .slide-nav-open & {

    &.topNav #sn-site-logo { display: none; }

  }

}
// no sub-nav
#topNavPlaceholder {
  height:calc((
  
  30 +
  
  
  35
  
  )*1px);
}
.has-sub-nav #topNavPlaceholder {
  height:calc((
  
  30 +
  
  
  35 +
  
  
  40
  
  )*1px);
}

.has-sub-nav .home:not(.newsPage) #topNav.topNav,
.has-sub-nav .home:not(.newsPage) #topNav.topNav.layout-3 {

  // #topNavPlaceholder,
  + #topNavPlaceholder {
    height: calc((
    
      
      80 +
      
      
      30 +
      
      
      35
      
    
    )*1px);
  }

  // .nav-fixed + #topNavPlaceholder,
  &.nav-fixed + #topNavPlaceholder {
    height: calc((
    
      
      80 +
      
      
      35
      
    
    )*1px);
  }

}

// things to check
//   responsiveness
//   if nav is fixed
//   if nav has logo
//
